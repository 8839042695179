<template>
     <v-card
        elevation="10"
        class="mx-auto"
    >

        <v-toolbar
            dark
            cards                
            flat
            outlined
            color="primary"
        >
            <v-card-title class="text-h6 font-weight-regular">
            {{cmpOptions.title}}
            </v-card-title>
            <v-spacer> </v-spacer>
            <v-btn 
                @click= "close($event)"
                icon>
                <v-icon>mdi-close-thick</v-icon>
            </v-btn>
 
        </v-toolbar>
        <v-divider></v-divider>
        <v-container>
            <gmap-map ref="gmap" :center="mapOptions.center"
                :zoom="cmpOptions.zoom"
                mapTypeId= 'hybrid'
                     :options="{
                        scrollwheel: mapOptions.scrollwheel,
                        zoomControl: mapOptions.zoomControl,
                        mapTypeControl: mapOptions.mapTypeControl,
                        streetViewControl: false,
                        scaleControl: mapOptions.scaleControl,
                        fullscreenControl: mapOptions.fullscreenControl
                    }"
                style="width: 100%; height: 700px"
            >


                <template v-for="(field, idx) in this.mapFields" ,:key="field.id" >
                    <template v-for="(polygon, index) in field.polygon" ,:key="index" >
                            <gmap-polygon 
                                :key="field.id + index.toString()"
                                :paths="polygon" 
                                :editable="false"
                                :clickable="true"
                                @click="showInfo($event,field,idx)"
                                :options="{
                                        fillColor: 'red',
                                        fillOpacity: 0.3,
                                        strokeOpacity: 0.8,
                                        strokeWeight: 1
                                }"
                                :ref="field.id + index.toString()">
                            </gmap-polygon>
                        </template>
                </template>
                <gmap-info-window
                    :options="infoOptions.options"
                    :position="infoOptions.position"
                    :opened="infoOptions.opened"
                    :content="infoOptions.content"
                    @closeclick="infoOptions.opened=false">
                </gmap-info-window>

            </gmap-map>
        </v-container>
    </v-card>
</template>

<script>
    // import * as VueGoogleMaps from 'vue2-google-maps'
    import { getGoogleMapsAPI  } from 'gmap-vue'


    export default {

        props:{
            fields: {
                required: false,
                type: Object,
                default: () => undefined
            },
            options:{
                required: false,
                type: Object
            },
            isVisible:{                
                required: true,
                default: false,
                type: Boolean
            }
        },

        data() {
            return {

                mapOptions:{
                    center: {lat:  47.997791, lng: 7.842609},
                    scrollwheel: true,
                    zoomControl: true,
                    mapTypeControl: true,
                    scaleControl: true,
                    fullscreenControl: true,
                },
                infoOptions:{
                    position: undefined,                   
                    opened: false,
                    currentKey: null,
                    options: {
                        content: '',
                        pixelOffset: {
                        width: 0,
                        height: 0
                        },
                    },
                },
                defaultOptions:{
                        title: 'Title',
                        zoom: 14,
                },

                mapFields:[
                    {id:undefined, mapPolygon:undefined}
                ],
  
            }
        },

        computed:{
            google: getGoogleMapsAPI ,

            cmpOptions:function(){
            console.debug('cmpOptions');
               let res = {
                    title: this.options.title ? this.options.title : this.defaultOptions.title,
                    zoom: this.options.zoom ? this.options.zoom : this.defaultOptions.zoom,
 
                };
                console.debug(res);
                return res;
            },



        },

        methods:{

            showInfo(event, item){
                console.debug('showInfo', event, item)

                let content = ''+                
                '<b>' +  item.name + '</b><br><br>' +
                'Position: ' +
                event.latLng.lat().toFixed(6) +
                ',' +
                event.latLng.lng().toFixed(6) +
                '<br>' +
                'Fläche: ' + (item.area_ha * 100).toFixed(2) + 'Ar' +
                 '<br>' +
                'Anbauart: ' + item.cultivation_species_caption;
 


                console.debug(content)
                
                this.infoOptions.position = event.latLng;
                this.infoOptions.options.content = content;
                this.infoOptions.opened = true;
                setTimeout(() => {
                    this.infoOptions.position = undefined;
                    this.infoOptions.options.content = "";
                    this.infoOptions.opened = false;
                }, 2000);
            },


            close(event){
                console.debug('close', event)
                this.$emit('update:isVisible', false)             
            },
            
            refresh(data){
                console.debug('refresh', data, this.google)                
                this.mapOptions.center =  this.fields.center;
                this.refreshPaths(data.items ? data.items : data)
            },  

            refreshPaths(fields){
               console.debug('refreshPaths', fields)
                if (Array.isArray(fields)){
                    
                    this.field = {};
                    for(let item of fields){
                        item.changed = false;
                    }
                    this.mapFields = JSON.parse(JSON.stringify(fields));                  
                }
                else{
                    this.mapFields = [];
                    this.mapFields.push(JSON.parse(JSON.stringify(fields)));
               }

            },


        },
        watch: { 
            fields() { // watch it
                console.log('Prop paths changed: ', this.fields)
                if(this.fields != undefined){
                    this.refresh(this.fields)
                }
            },
            isVisible(newVal){
                if(!newVal){
                    this.infoOptions.position = undefined;
                    this.infoOptions.options.content = "";
                    this.infoOptions.opened = false;
                }
            }
        },
        mounted() {
            console.log('mounted', this.fields);
            //this.mapFields = this.fields;
                 if(this.fields != undefined){
                    this.refresh(this.fields);
                }
           //console.debug('test',this.mapFields[0].polygonMaps[0].path)
        },
 
    }

</script>